/// <reference path="../../typings/graphql.d.ts"/>

import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Link, graphql } from 'gatsby'

import Layout, { extractData, THEMES } from '../components/layout'
import { Page, EmbedContainer } from '../components/utils'

import Section, { SubSection } from '../components/section'
import { Cinema, CINEMA_META } from '../model/model'
import createStore from '../model/create-store'
import { Provider } from 'react-redux';
import ConnectedUpdater from '../components/updater';
import { NewsletterForm } from '../components/newsletter-form';
import { Tracking } from '../model/utils';

const MapContainer = styled(EmbedContainer)`
  margin-bottom: 1rem;
`
const TicketsTable = styled.table`
  width: fit-content;
  margin: auto;
  & td:last-child {
    padding-right: 0;
    min-width: 80px;
    text-align: right;
  }
`

const Highlight = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border: 1px solid white;
padding: 1rem;
width: fit-content;
align-self: center;
& p {
  margin: 0;
  font-size: 0.9rem;
  color: ${props => props.theme.colors.attenuate};
}

& h3 {
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}

& span {
  color: ${props => props.theme.colors.highlight};
}
`

const UnderlinedLink = styled.a`
  text-decoration: underline;
`

interface PageProps {
  cinemas: Cinema[]
}

const O6InfoPage: React.SFC<PageProps> = ( {cinemas} ) => (
  <Layout showBackButton={true} cinemas={cinemas} title="Informazioni">
    <Page>
      <Section title="Come Raggiungerci">
        <p>
        Il Cinema Odeon 6 mette a disposizione 6 sale cinematografiche al centro di Ascoli Piceno.
        </p>
        <MapContainer>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.603291497441!2d13.576306015194048!3d42.86010907915645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1331fd05f9b067e7%3A0x28ca631a482d023e!2sCinema+Multisala+Odeon!5e0!3m2!1sen!2sit!4v1481906315113"
            width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
        </MapContainer>
      </Section>
      <Section title="Prezzi biglietti">
        <Highlight>
          <h3>Il Lunedi biglietto unico a <span>€5</span>!</h3>
          <p>Esclusi festivi e pre-festivi</p>
        </Highlight>
        <TicketsTable>
          <tbody>
            <tr><td>Intero</td><td>&euro; 7.00</td></tr>
            <tr><td>Ridotto</td><td>&euro; 5.00</td></tr>
            <tr><td>Over 65 Feriali</td><td>&euro; 4.00</td></tr>
            <tr><td>Over 65 Festivi e Prefestivi</td><td>&euro; 5.00</td></tr>
          </tbody>
        </TicketsTable>
      </Section>
      <Section title="Servizi">
        <SubSection title="6 SALE">
          Il Cinema Odeon 6 mette a disposizione 6 sale cinematografiche per fornire una vasta scelta.
        </SubSection>
        <SubSection title="BAR">
          All'interno del Cinema Odeon6 è possibile acquistare pop corn, snack e bevande.
        </SubSection>
        <SubSection title="LOCALI CLIMATIZZATI">
          Tutti i locali del complesso sono climatizzati per consentire una piacevole visione dei film in qualunque periodo dell'anno.
        </SubSection>
        <SubSection title="SALE A GRADONI E SPAZIOSE POLTRONE">
          L'adozione di comode poltrone disposte su gradoni in ampie file permette una perfetta e confortevole visione del film.
        </SubSection>
        <SubSection title="VIDEO">
          Tutte le sale sono equipaggiate con proiettori Sony Digital Cinema 4K che danno una risoluzione 4 volte superiore ai normali proiettori digitali 2K.<br/>
        </SubSection>
        <SubSection title="AMPIO PARCHEGGIO">
          Il Cinema Odeon 6 si trova di fianco l'ampio parcheggio Ex Gil.
        </SubSection>
        <SubSection title="ASSISTENZA DISABILI">
          Il Cinema Odeon 6 è attrezzato per soddisfare le esigenze dei disabili.
        </SubSection>
      </Section>
      <Section title="Contatti">
        <SubSection title="Orario Spettacoli">
          <p>
            SEGRETERIA SPETTACOLI: <a href="tel: +390736781690">0736.781690</a> <br/>
            E' un numero attivo 24 ore su 24. Un messaggio automatico fornisce il nome dei film attualmente in programmazione e gli orari di proiezioni, nonchè eventuali eventi speciali.
          </p>
          <p>
            SERVIZIO WHATSAPP: <a href='https://wa.me/393341539100'>Whatsapp 334.1539100</a><br/>
            Mandaci un messaggio "OK" per restare informato su nuove uscite e promozioni!
          </p>
          NEWSLETTER:
          <NewsletterForm text={null} cinemaId={cinemas[0].id} formName="info" tags={[]} movie={null}
              onSuccess={(email, formName) => {
                Tracking.trackEngagement('info/subscribe-to-newsletter', formName)
              }}/>

        </SubSection>
        <SubSection title="Domande e Informazioni">
          <p>
            Contattaci su <UnderlinedLink href={CINEMA_META.odeon6.messenger}>Facebook Messenger</UnderlinedLink> per qualunque domanda!<br/>
          </p>
        </SubSection>
        <SubSection title="Recapito">
          Cinema Odeon 6<br/>
          Viale Marcello Federici, 82<br/>
          63100 - Ascoli Piceno
        </SubSection>
      </Section>
      <Section title="Privacy">
        <p>
          Per informazioni sul trattamento dei dati leggi la nostra <Link to='/privacy-policy'>informativa privacy</Link>
        </p>
      </Section>
    </Page>
  </Layout>
)

const MDSInfoPage: React.SFC<PageProps> = ( {cinemas} ) => {
  return (
  <Layout showBackButton={true} cinemas={cinemas} title="Informazioni">
    <Page>
      <Section title="Come Raggiungerci">
        <p>
        Il Multiplex delle Stelle si trova all'interno del centro commerciale Città delle Stelle
        vicino Castel di Lama (Ascoli Piceno)
        </p>
        <MapContainer>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11418.653019203424!2d13.702682743471195!3d42.85669224318346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1331fa666176887d%3A0x48a1b8c9178a71d2!2sMultiplex+delle+Stelle!5e0!3m2!1sen!2sit!4v1546470594533"
            width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
        </MapContainer>
      </Section>
      <Section title="Prezzi biglietti">
        <Highlight>
          <h3>Il Martedi biglietto unico a <span>€5</span>!</h3>
          <p>Esclusi festivi e pre-festivi</p>
        </Highlight>
        <TicketsTable>
          <tbody>
            <tr><td>Intero</td><td>&euro; 7.00</td></tr>
            <tr><td>Intero 3D</td><td>&euro; 10.00</td></tr>
            <tr><td>Ridotto</td><td>&euro; 5.00</td></tr>
            <tr><td>Ridotto 3D</td><td>&euro; 7.50</td></tr>
            <tr><td>Anziano (oltre i 65 anni, non valido i festivi)</td><td>&euro; 4.00</td></tr>
            <tr><td>Anziano 3D (oltre i 65 anni, non valido i festivi)</td><td>&euro; 6.00</td></tr>
          </tbody>
        </TicketsTable>
      </Section>
      <Section title="Servizi">
        <SubSection title="BAR">
          All'interno del Multiplex delle Stelle è possibile acquistare pop corn, snack e tanto altro.
        </SubSection>
        <SubSection title="MEGASCHERMI FINO A 17 METRI">
          Il Multiplex delle Stelle offre dodici sale, con schermi larghi fino a 17 metri, per il raggiungimento del massimo grado di immersione e intrattenimento.
        </SubSection>
        <SubSection title="LOCALI CLIMATIZZATI">
          Tutti i locali del complesso sono climatizzati per consentire una piacevole visione dei film in qualunque periodo dell'anno.
        </SubSection>
        <SubSection title="SALE A GRADONI E SPAZIOSE POLTRONE">
          L'adozione di comode poltrone disposte su gradoni in ampie file distanti tra loro più di 120 cm permette una perfetta e confortevole visione del film.
        </SubSection>
        <SubSection title="VIDEO">
          Tutte le sale sono equipaggiate con proiettori Sony Digital Cinema 4K che danno una risoluzione 4 volte superiore ai normali proiettori digitali 2K.<br/>
          Per il 3D vengono utilizati impianti Sony Digital Cinema 3D con 2 obiettivi sovrapposti per dare la migliore definizione delle immagini senza affaticare la vista.
        </SubSection>
        <SubSection title="AMPIO PARCHEGGIO">
          Il Multiplex delle Stelle può contare sulla presenza di un ampio parcheggio di oltre 2500 posti auto.
        </SubSection>
        <SubSection title="ASSISTENZA DISABILI">
          Il Multiplex delle Stelle è attrezzato per soddisfare le esigenze dei disabili
        </SubSection>
      </Section>
      <Section title="Contatti">
        <SubSection title="Orario Spettacoli">
          <p>
            SEGRETERIA SPETTACOLI: <a href="tel: +390736815220">0736.815220</a> <br/>
            E' un numero attivo 24 ore su 24. Un messaggio automatico fornisce il nome dei film attualmente in programmazione e gli orari di proiezioni, nonchè eventuali eventi speciali.
          </p>
          <p>
            SERVIZIO WHATSAPP: <a href='https://wa.me/393341539100'>Whatsapp 334.1539100</a><br/>
            Mandaci un messaggio "OK" per restare informato su nuove uscite e promozioni!
          </p>
        </SubSection>
        <SubSection title="Domande e Informazioni">
          <p>
            Contattaci su <UnderlinedLink href={CINEMA_META.mds.messenger}>Facebook Messenger</UnderlinedLink> per qualunque domanda!<br/>
          </p>
        </SubSection>
        <SubSection title="Recapito">
          Multiplex delle Stelle<br/>
          c/o Centro Commerciale Città delle Stelle<br/>
          Viale dei mutilati del lavoro n.106<br/>
          Zona industriale Campolungo <br/>
          63100 - Ascoli Piceno
        </SubSection>
      </Section>
      <Section title="Privacy">
        <p>
          Per informazioni sul trattamento dei dati leggi la nostra <a href="https://www.iubenda.com/privacy-policy/83855276" title="Privacy Policy ">informativa privacy</a>
        </p>
      </Section>
    </Page>
  </Layout>
)}

const StaticPage: React.SFC<any> = ( query ) => {
  const { cinemas, mainCinemaId } = extractData(query)
  const InfoPage = cinemas[0].id === 'mds'
    ? MDSInfoPage
    : O6InfoPage
  const store = createStore("home", cinemas, mainCinemaId, false)

  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <Provider store={store}>
        <>
          <ConnectedUpdater/>
          <InfoPage cinemas={cinemas}/>
        </>
      </Provider>
    </ThemeProvider>
  )
}

export default StaticPage

export const query = graphql`
query InfoPageQuery {
  ...alldata
}
`